const ChevronIcon = () => {
  return (
    <svg
      fill="#000000"
      width="14px"
      height="14px"
      viewBox="-3.75 0 16 16"
      id="chevron-right-16px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        <path
          id="Path_140" 
          d="M-24,16a.5.5,0,0,1-.354-.146.5.5,0,0,1,0-.708L-17.207,8-24.354.854a.5.5,0,0,1,0-.708.5.5,0,0,1,.708,0l7.5,7.5a.5.5,0,0,1,0,.708l-7.5,7.5A.5.5,0,0,1-24,16Z"
          transform="translate(24.5 0)"
        ></path>
      </g>
    </svg>
  );
};

export default ChevronIcon;
