export const TYPE_USER = {
    USER_PREMIUM : "1",
    USER_DEMO : "2",
    STUDENT:"3",
}
export const SOCIAL_MEDIA_LINKS = {
    TIKTOK: "https://www.tiktok.com/@enarmoficial?_t=8qbbYc8A526&_r=1",
    INSTAGRAM: "https://instagram.com/plataformaenarm?utm_medium=3wcopy_link",
    TELEGRAM: "https://t.me/PlataformaENARM",
    PINTEREST: "https://pin.it/1UbrEA8",
    TWITTER: "https://twitter.com/EnarMoficial?s=09",
    WHATSAPP: "https://wa.me/message/FSXX6B5U7XDWF1",
    FACEBOOK: "https://www.facebook.com/plataformaenarm",
    YOUTUBE: "https://youtube.com/@PlataformaENARMOficial?feature=shared",
    WHATSAPP_CHANNEL: "https://whatsapp.com/channel/0029Va55YOO7Noa5jWtRxi1x"
  };