import React from 'react'

const Item4 = () => {
  return (
    <ul className="ml-16">
      <li><p className="regular-parraf">Desde el día en que te inscribes al curso de Plataforma ENARM <span><strong>hasta el ultimo día del ENARM</strong></span></p></li>
    </ul>
  )
}

export default Item4;