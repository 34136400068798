import { createContext, useState } from "react";

export const SimulatorContext = createContext(null);

const SimulatorProvider = ({ children }) => {

  const [questions, setQuestions] = useState([
    {
      question_id: 1,
      case: "Trabajador de la construcción que acude a consulta por la presencia de dermatosis localizada en manos, caracterizada por la presencia de lesiones eritematosas pruriginosas, se sospecha dermatitis por contacto.",
      question: "¿Cuál es el principal alérgeno encontrado?",
      isAnswered: false,
      answers_list: [
        "resina epoxi, cromo, niquel, cobalto",
        "resorcinol, niquel, derivador acrílicos",
        "Látex, formaldehido, abitol",
        "Propoliz, cloracetamida, resinas de maderas",
      ],
      correct_answer_position: 0,
      answer_selected: 3,
      tip_response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina.",
      response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina."
    },
    {
      question_id: 2,
      case: "Mujer previamente sana se presenta a consulta con dolor de 15 horas de evolución, tiene antecedente del mismo dolor de manera episodica en el mismo lugar que no dura mas de 2 horas, sin embargo no habia buscado ayuda médica hasta este momento. a la ef fc 95 lpm t 38.5c°, aumento de la sensibilidad en el cuadrante superior derecho.",
      question: "¿Qué estudio de laboratorio solicita de primer instancia?",
      isAnswered: false,
      answers_list: [
        "quimica sanguinea",
        "tiempos de coagulación",
        "biometría hemática",
        "pruebas de función hepática",
      ],
      correct_answer_position: 3,
      answer_selected: 3,
      tip_response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina.",
      response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina."
    },
    {
      question_id: 3,
      case: "Trabajador de la construcción que acude a consulta por la presencia de dermatosis localizada en manos, caracterizada por la presencia de lesiones eritematosas pruriginosas, se sospecha dermatitis por contacto.",
      question: "¿Cuál es el principal alérgeno encontrado?",
      isAnswered: false, answers_list: [
        "resina epoxi, cromo, niquel, cobalto",
        "resorcinol, niquel, derivador acrílicos",
        "Látex, formaldehido, abitol",
        "Propoliz, cloracetamida, resinas de maderas",
      ],
      correct_answer_position: 0,
      answer_selected: 1,
      tip_response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina.",
      response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina."
    },
    {
      question_id: 4,
      case: "Mujer previamente sana se presenta a consulta con dolor de 15 horas de evolución, tiene antecedente del mismo dolor de manera episodica en el mismo lugar que no dura mas de 2 horas, sin embargo no habia buscado ayuda médica hasta este momento. a la ef fc 95 lpm t 38.5c°, aumento de la sensibilidad en el cuadrante superior derecho.",
      question: "¿Qué estudio de laboratorio solicita de primer instancia?",
      isAnswered: false,
      answers_list: [
        "quimica sanguinea",
        "tiempos de coagulación",
        "biometría hemática",
        "pruebas de función hepática",
      ],
      correct_answer_position: 0,
      answer_selected: 2,
      tip_response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina.",
      response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina."
    },
    {
      question_id: 5,
      case: "Trabajador de la construcción que acude a consulta por la presencia de dermatosis localizada en manos, caracterizada por la presencia de lesiones eritematosas pruriginosas, se sospecha dermatitis por contacto.",
      question: "¿Cuál es el principal alérgeno encontrado?",
      isAnswered: false, answers_list: [
        "resina epoxi, cromo, niquel, cobalto",
        "resorcinol, niquel, derivador acrílicos",
        "Látex, formaldehido, abitol",
        "Propoliz, cloracetamida, resinas de maderas",
      ],
      correct_answer_position: 0,
      answer_selected: null,
      tip_response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina.",
      response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina."
    },
    {
      question_id: 6,
      case: "Trabajador de la construcción que acude a consulta por la presencia de dermatosis localizada en manos, caracterizada por la presencia de lesiones eritematosas pruriginosas, se sospecha dermatitis por contacto.",
      question: "¿Cuál es el principal alérgeno encontrado?",
      isAnswered: false, answers_list: [
        "resina epoxi, cromo, niquel, cobalto",
        "resorcinol, niquel, derivador acrílicos",
        "Látex, formaldehido, abitol",
        "Propoliz, cloracetamida, resinas de maderas",
      ],
      correct_answer_position: 0,
      answer_selected: null,
      tip_response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina.",
      response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina."
    },
    {
      question_id: 7,
      case: "Trabajador de la construcción que acude a consulta por la presencia de dermatosis localizada en manos, caracterizada por la presencia de lesiones eritematosas pruriginosas, se sospecha dermatitis por contacto.",
      question: "¿Cuál es el principal alérgeno encontrado?",
      isAnswered: false, answers_list: [
        "resina epoxi, cromo, niquel, cobalto",
        "resorcinol, niquel, derivador acrílicos",
        "Látex, formaldehido, abitol",
        "Propoliz, cloracetamida, resinas de maderas",
      ],
      correct_answer_position: 0,
      answer_selected: null,
      tip_response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina.",
      response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina."
    },
    {
      question_id: 8,
      case: "Mujer previamente sana se presenta a consulta con dolor de 15 horas de evolución, tiene antecedente del mismo dolor de manera episodica en el mismo lugar que no dura mas de 2 horas, sin embargo no habia buscado ayuda médica hasta este momento. a la ef fc 95 lpm t 38.5c°, aumento de la sensibilidad en el cuadrante superior derecho.",
      question: "¿Qué estudio de laboratorio solicita de primer instancia?",
      isAnswered: false, answers_list: [
        "quimica sanguinea",
        "tiempos de coagulación",
        "biometría hemática",
        "pruebas de función hepática",
      ],
      correct_answer_position: 0,
      answer_selected: null,
      tip_response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina.",
      response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina."
    },
    {
      question_id: 9,
      case: "Trabajador de la construcción que acude a consulta por la presencia de dermatosis localizada en manos, caracterizada por la presencia de lesiones eritematosas pruriginosas, se sospecha dermatitis por contacto.",
      question: "¿Cuál es el principal alérgeno encontrado?",
      isAnswered: false, answers_list: [
        "resina epoxi, cromo, niquel, cobalto",
        "resorcinol, niquel, derivador acrílicos",
        "Látex, formaldehido, abitol",
        "Propoliz, cloracetamida, resinas de maderas",
      ],
      correct_answer_position: 0,
      answer_selected: null,
      tip_response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina.",
      response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina."
    },
    {
      question_id: 10,
      case: "Trabajador de la construcción que acude a consulta por la presencia de dermatosis localizada en manos, caracterizada por la presencia de lesiones eritematosas pruriginosas, se sospecha dermatitis por contacto.",
      question: "¿Cuál es el principal alérgeno encontrado?",
      isAnswered: false, answers_list: [
        "resina epoxi, cromo, niquel, cobalto",
        "resorcinol, niquel, derivador acrílicos",
        "Látex, formaldehido, abitol",
        "Propoliz, cloracetamida, resinas de maderas",
      ],
      correct_answer_position: 0,
      answer_selected: null,
      tip_response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina.",
      response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina."
    },
    {
      question_id: 11,
      case: "Trabajador de la construcción que acude a consulta por la presencia de dermatosis localizada en manos, caracterizada por la presencia de lesiones eritematosas pruriginosas, se sospecha dermatitis por contacto.",
      question: "¿Cuál es el principal alérgeno encontrado?",
      isAnswered: false, answers_list: [
        "resina epoxi, cromo, niquel, cobalto",
        "resorcinol, niquel, derivador acrílicos",
        "Látex, formaldehido, abitol",
        "Propoliz, cloracetamida, resinas de maderas",
      ],
      correct_answer_position: 0,
      answer_selected: null,
      tip_response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina.",
      response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina."
    },
    {
      question_id: 12,
      case: "Mujer previamente sana se presenta a consulta con dolor de 15 horas de evolución, tiene antecedente del mismo dolor de manera episodica en el mismo lugar que no dura mas de 2 horas, sin embargo no habia buscado ayuda médica hasta este momento. a la ef fc 95 lpm t 38.5c°, aumento de la sensibilidad en el cuadrante superior derecho.",
      question: "¿Qué estudio de laboratorio solicita de primer instancia?",
      isAnswered: false, answers_list: [
        "quimica sanguinea",
        "tiempos de coagulación",
        "biometría hemática",
        "pruebas de función hepática",
      ],
      correct_answer_position: 0,
      answer_selected: null,
      tip_response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina.",
      response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina."
    },
    {
      question_id: 13,
      case: "Trabajador de la construcción que acude a consulta por la presencia de dermatosis localizada en manos, caracterizada por la presencia de lesiones eritematosas pruriginosas, se sospecha dermatitis por contacto.",
      question: "¿Cuál es el principal alérgeno encontrado?",
      isAnswered: false, answers_list: [
        "resina epoxi, cromo, niquel, cobalto",
        "resorcinol, niquel, derivador acrílicos",
        "Látex, formaldehido, abitol",
        "Propoliz, cloracetamida, resinas de maderas",
      ],
      correct_answer_position: 0,
      answer_selected: null,
      tip_response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina.",
      response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina."
    },
    {
      question_id: 14,
      case: "Trabajador de la construcción que acude a consulta por la presencia de dermatosis localizada en manos, caracterizada por la presencia de lesiones eritematosas pruriginosas, se sospecha dermatitis por contacto.",
      question: "¿Cuál es el principal alérgeno encontrado?",
      isAnswered: false, answers_list: [
        "resina epoxi, cromo, niquel, cobalto",
        "resorcinol, niquel, derivador acrílicos",
        "Látex, formaldehido, abitol",
        "Propoliz, cloracetamida, resinas de maderas",
      ],
      correct_answer_position: 0,
      answer_selected: null,
      tip_response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina.",
      response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina."
    },
    {
      question_id: 15,
      case: "Trabajador de la construcción que acude a consulta por la presencia de dermatosis localizada en manos, caracterizada por la presencia de lesiones eritematosas pruriginosas, se sospecha dermatitis por contacto.",
      question: "¿Cuál es el principal alérgeno encontrado?",
      isAnswered: false, answers_list: [
        "resina epoxi, cromo, niquel, cobalto",
        "resorcinol, niquel, derivador acrílicos",
        "Látex, formaldehido, abitol",
        "Propoliz, cloracetamida, resinas de maderas",
      ],
      correct_answer_position: 0,
      answer_selected: null,
      tip_response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina.",
      response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina."
    },
    {
      question_id: 16,
      case: "Trabajador de la construcción que acude a consulta por la presencia de dermatosis localizada en manos, caracterizada por la presencia de lesiones eritematosas pruriginosas, se sospecha dermatitis por contacto.",
      question: "¿Cuál es el principal alérgeno encontrado?",
      isAnswered: false, answers_list: [
        "resina epoxi, cromo, niquel, cobalto",
        "resorcinol, niquel, derivador acrílicos",
        "Látex, formaldehido, abitol",
        "Propoliz, cloracetamida, resinas de maderas",
      ],
      correct_answer_position: 0,
      answer_selected: null,
      tip_response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina.",
      response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina."
    },
    {
      question_id: 17,
      case: "Trabajador de la construcción que acude a consulta por la presencia de dermatosis localizada en manos, caracterizada por la presencia de lesiones eritematosas pruriginosas, se sospecha dermatitis por contacto.",
      question: "¿Cuál es el principal alérgeno encontrado?",
      isAnswered: false, answers_list: [
        "resina epoxi, cromo, niquel, cobalto",
        "resorcinol, niquel, derivador acrílicos",
        "Látex, formaldehido, abitol",
        "Propoliz, cloracetamida, resinas de maderas",
      ],
      correct_answer_position: 0,
      answer_selected: null,
      tip_response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina.",
      response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina."
    },
    {
      question_id: 18,
      case: "Trabajador de la construcción que acude a consulta por la presencia de dermatosis localizada en manos, caracterizada por la presencia de lesiones eritematosas pruriginosas, se sospecha dermatitis por contacto.",
      question: "¿Cuál es el principal alérgeno encontrado?",
      isAnswered: false, answers_list: [
        "resina epoxi, cromo, niquel, cobalto",
        "resorcinol, niquel, derivador acrílicos",
        "Látex, formaldehido, abitol",
        "Propoliz, cloracetamida, resinas de maderas",
      ],
      correct_answer_position: 0,
      answer_selected: null,
      tip_response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina.",
      response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina."
    },
    {
      question_id: 19,
      case: "Trabajador de la construcción que acude a consulta por la presencia de dermatosis localizada en manos, caracterizada por la presencia de lesiones eritematosas pruriginosas, se sospecha dermatitis por contacto.",
      question: "¿Cuál es el principal alérgeno encontrado?",
      isAnswered: false, answers_list: [
        "resina epoxi, cromo, niquel, cobalto",
        "resorcinol, niquel, derivador acrílicos",
        "Látex, formaldehido, abitol",
        "Propoliz, cloracetamida, resinas de maderas",
      ],
      correct_answer_position: 0,
      answer_selected: null,
      tip_response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina.",
      response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina."
    },
    {
      question_id: 20,
      case: "Trabajador de la construcción que acude a consulta por la presencia de dermatosis localizada en manos, caracterizada por la presencia de lesiones eritematosas pruriginosas, se sospecha dermatitis por contacto.",
      question: "¿Cuál es el principal alérgeno encontrado?",
      isAnswered: false, answers_list: [
        "resina epoxi, cromo, niquel, cobalto",
        "resorcinol, niquel, derivador acrílicos",
        "Látex, formaldehido, abitol",
        "Propoliz, cloracetamida, resinas de maderas",
      ],
      correct_answer_position: 0,
      answer_selected: null,
      tip_response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina.",
      response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina."
    },
    {
      question_id: 21,
      case: "Trabajador de la construcción que acude a consulta por la presencia de dermatosis localizada en manos, caracterizada por la presencia de lesiones eritematosas pruriginosas, se sospecha dermatitis por contacto.",
      question: "¿Cuál es el principal alérgeno encontrado?",
      isAnswered: false, answers_list: [
        "resina epoxi, cromo, niquel, cobalto",
        "resorcinol, niquel, derivador acrílicos",
        "Látex, formaldehido, abitol",
        "Propoliz, cloracetamida, resinas de maderas",
      ],
      correct_answer_position: 0,
      answer_selected: null,
      tip_response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina.",
      response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina."
    },
    {
      question_id: 22,
      case: "Trabajador de la construcción que acude a consulta por la presencia de dermatosis localizada en manos, caracterizada por la presencia de lesiones eritematosas pruriginosas, se sospecha dermatitis por contacto.",
      question: "¿Cuál es el principal alérgeno encontrado?",
      isAnswered: false, answers_list: [
        "resina epoxi, cromo, niquel, cobalto",
        "resorcinol, niquel, derivador acrílicos",
        "Látex, formaldehido, abitol",
        "Propoliz, cloracetamida, resinas de maderas",
      ],
      correct_answer_position: 0,
      answer_selected: null,
      tip_response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina.",
      response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina."
    },
    {
      question_id: 23,
      case: "Trabajador de la construcción que acude a consulta por la presencia de dermatosis localizada en manos, caracterizada por la presencia de lesiones eritematosas pruriginosas, se sospecha dermatitis por contacto.",
      question: "¿Cuál es el principal alérgeno encontrado?",
      isAnswered: false, answers_list: [
        "resina epoxi, cromo, niquel, cobalto",
        "resorcinol, niquel, derivador acrílicos",
        "Látex, formaldehido, abitol",
        "Propoliz, cloracetamida, resinas de maderas",
      ],
      correct_answer_position: 0,
      answer_selected: null,
      tip_response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina.",
      response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina."
    },
    {
      question_id: 24,
      case: "Trabajador de la construcción que acude a consulta por la presencia de dermatosis localizada en manos, caracterizada por la presencia de lesiones eritematosas pruriginosas, se sospecha dermatitis por contacto.",
      question: "¿Cuál es el principal alérgeno encontrado?",
      isAnswered: false, answers_list: [
        "resina epoxi, cromo, niquel, cobalto",
        "resorcinol, niquel, derivador acrílicos",
        "Látex, formaldehido, abitol",
        "Propoliz, cloracetamida, resinas de maderas",
      ],
      correct_answer_position: 0,
      answer_selected: null,
      tip_response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina.",
      response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina."
    },
    {
      question_id: 25,
      case: "Trabajador de la construcción que acude a consulta por la presencia de dermatosis localizada en manos, caracterizada por la presencia de lesiones eritematosas pruriginosas, se sospecha dermatitis por contacto.",
      question: "¿Cuál es el principal alérgeno encontrado?",
      isAnswered: false, answers_list: [
        "resina epoxi, cromo, niquel, cobalto",
        "resorcinol, niquel, derivador acrílicos",
        "Látex, formaldehido, abitol",
        "Propoliz, cloracetamida, resinas de maderas",
      ],
      correct_answer_position: 0,
      answer_selected: null,
      tip_response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina.",
      response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina."
    },
    {
      question_id: 26,
      case: "Trabajador de la construcción que acude a consulta por la presencia de dermatosis localizada en manos, caracterizada por la presencia de lesiones eritematosas pruriginosas, se sospecha dermatitis por contacto.",
      question: "¿Cuál es el principal alérgeno encontrado?",
      isAnswered: false, answers_list: [
        "resina epoxi, cromo, niquel, cobalto",
        "resorcinol, niquel, derivador acrílicos",
        "Látex, formaldehido, abitol",
        "Propoliz, cloracetamida, resinas de maderas",
      ],
      correct_answer_position: 0,
      answer_selected: null,
      tip_response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina.",
      response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina."
    },
    {
      question_id: 27,
      case: "Trabajador de la construcción que acude a consulta por la presencia de dermatosis localizada en manos, caracterizada por la presencia de lesiones eritematosas pruriginosas, se sospecha dermatitis por contacto.",
      question: "¿Cuál es el principal alérgeno encontrado?",
      isAnswered: false, answers_list: [
        "resina epoxi, cromo, niquel, cobalto",
        "resorcinol, niquel, derivador acrílicos",
        "Látex, formaldehido, abitol",
        "Propoliz, cloracetamida, resinas de maderas",
      ],
      correct_answer_position: 0,
      answer_selected: null,
      tip_response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina.",
      response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina."
    },
    {
      question_id: 28,
      case: "Trabajador de la construcción que acude a consulta por la presencia de dermatosis localizada en manos, caracterizada por la presencia de lesiones eritematosas pruriginosas, se sospecha dermatitis por contacto.",
      question: "¿Cuál es el principal alérgeno encontrado?",
      isAnswered: false, answers_list: [
        "resina epoxi, cromo, niquel, cobalto",
        "resorcinol, niquel, derivador acrílicos",
        "Látex, formaldehido, abitol",
        "Propoliz, cloracetamida, resinas de maderas",
      ],
      correct_answer_position: 0,
      answer_selected: null,
      tip_response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina.",
      response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina."
    },
    {
      question_id: 29,
      case: "Trabajador de la construcción que acude a consulta por la presencia de dermatosis localizada en manos, caracterizada por la presencia de lesiones eritematosas pruriginosas, se sospecha dermatitis por contacto.",
      question: "¿Cuál es el principal alérgeno encontrado?",
      isAnswered: false, answers_list: [
        "resina epoxi, cromo, niquel, cobalto",
        "resorcinol, niquel, derivador acrílicos",
        "Látex, formaldehido, abitol",
        "Propoliz, cloracetamida, resinas de maderas",
      ],
      correct_answer_position: 0,
      answer_selected: null,
      tip_response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina.",
      response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina."
    },
    {
      question_id: 30,
      case: "Trabajador de la construcción que acude a consulta por la presencia de dermatosis localizada en manos, caracterizada por la presencia de lesiones eritematosas pruriginosas, se sospecha dermatitis por contacto.",
      question: "¿Cuál es el principal alérgeno encontrado?",
      isAnswered: false, answers_list: [
        "resina epoxi, cromo, niquel, cobalto",
        "resorcinol, niquel, derivador acrílicos",
        "Látex, formaldehido, abitol",
        "Propoliz, cloracetamida, resinas de maderas",
      ],
      correct_answer_position: 0,
      answer_selected: null,
      tip_response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina.",
      response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina."
    },
    {
      question_id: 31,
      case: "Trabajador de la construcción que acude a consulta por la presencia de dermatosis localizada en manos, caracterizada por la presencia de lesiones eritematosas pruriginosas, se sospecha dermatitis por contacto.",
      question: "¿Cuál es el principal alérgeno encontrado?",
      isAnswered: false, answers_list: [
        "resina epoxi, cromo, niquel, cobalto",
        "resorcinol, niquel, derivador acrílicos",
        "Látex, formaldehido, abitol",
        "Propoliz, cloracetamida, resinas de maderas",
      ],
      correct_answer_position: 0,
      answer_selected: null,
      tip_response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina.",
      response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina."
    },
    {
      question_id: 32,
      case: "Trabajador de la construcción que acude a consulta por la presencia de dermatosis localizada en manos, caracterizada por la presencia de lesiones eritematosas pruriginosas, se sospecha dermatitis por contacto.",
      question: "¿Cuál es el principal alérgeno encontrado?",
      isAnswered: false, answers_list: [
        "resina epoxi, cromo, niquel, cobalto",
        "resorcinol, niquel, derivador acrílicos",
        "Látex, formaldehido, abitol",
        "Propoliz, cloracetamida, resinas de maderas",
      ],
      correct_answer_position: 0,
      answer_selected: null,
      tip_response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina.",
      response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina."
    },
    {
      question_id: 33,
      case: "Trabajador de la construcción que acude a consulta por la presencia de dermatosis localizada en manos, caracterizada por la presencia de lesiones eritematosas pruriginosas, se sospecha dermatitis por contacto.",
      question: "¿Cuál es el principal alérgeno encontrado?",
      isAnswered: false, answers_list: [
        "resina epoxi, cromo, niquel, cobalto",
        "resorcinol, niquel, derivador acrílicos",
        "Látex, formaldehido, abitol",
        "Propoliz, cloracetamida, resinas de maderas",
      ],
      correct_answer_position: 0,
      answer_selected: null,
      tip_response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina.",
      response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina."
    },
    {
      question_id: 34,
      case: "Trabajador de la construcción que acude a consulta por la presencia de dermatosis localizada en manos, caracterizada por la presencia de lesiones eritematosas pruriginosas, se sospecha dermatitis por contacto.",
      question: "¿Cuál es el principal alérgeno encontrado?",
      isAnswered: false, answers_list: [
        "resina epoxi, cromo, niquel, cobalto",
        "resorcinol, niquel, derivador acrílicos",
        "Látex, formaldehido, abitol",
        "Propoliz, cloracetamida, resinas de maderas",
      ],
      correct_answer_position: 0,
      answer_selected: null,
      tip_response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina.",
      response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina."
    },
    {
      question_id: 35,
      case: "Trabajador de la construcción que acude a consulta por la presencia de dermatosis localizada en manos, caracterizada por la presencia de lesiones eritematosas pruriginosas, se sospecha dermatitis por contacto.",
      question: "¿Cuál es el principal alérgeno encontrado?",
      isAnswered: false, answers_list: [
        "resina epoxi, cromo, niquel, cobalto",
        "resorcinol, niquel, derivador acrílicos",
        "Látex, formaldehido, abitol",
        "Propoliz, cloracetamida, resinas de maderas",
      ],
      correct_answer_position: 0,
      answer_selected: null,
      tip_response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina.",
      response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina."
    },
    {
      question_id: 36,
      case: "Trabajador de la construcción que acude a consulta por la presencia de dermatosis localizada en manos, caracterizada por la presencia de lesiones eritematosas pruriginosas, se sospecha dermatitis por contacto.",
      question: "¿Cuál es el principal alérgeno encontrado?",
      isAnswered: false, answers_list: [
        "resina epoxi, cromo, niquel, cobalto",
        "resorcinol, niquel, derivador acrílicos",
        "Látex, formaldehido, abitol",
        "Propoliz, cloracetamida, resinas de maderas",
      ],
      correct_answer_position: 0,
      answer_selected: null,
      tip_response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina.",
      response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina."
    },
    {
      question_id: 37,
      case: "Trabajador de la construcción que acude a consulta por la presencia de dermatosis localizada en manos, caracterizada por la presencia de lesiones eritematosas pruriginosas, se sospecha dermatitis por contacto.",
      question: "¿Cuál es el principal alérgeno encontrado?",
      isAnswered: false, answers_list: [
        "resina epoxi, cromo, niquel, cobalto",
        "resorcinol, niquel, derivador acrílicos",
        "Látex, formaldehido, abitol",
        "Propoliz, cloracetamida, resinas de maderas",
      ],
      correct_answer_position: 0,
      answer_selected: null,
      tip_response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina.",
      response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina."
    },
    {
      question_id: 38,
      case: "Trabajador de la construcción que acude a consulta por la presencia de dermatosis localizada en manos, caracterizada por la presencia de lesiones eritematosas pruriginosas, se sospecha dermatitis por contacto.",
      question: "¿Cuál es el principal alérgeno encontrado?",
      isAnswered: false, answers_list: [
        "resina epoxi, cromo, niquel, cobalto",
        "resorcinol, niquel, derivador acrílicos",
        "Látex, formaldehido, abitol",
        "Propoliz, cloracetamida, resinas de maderas",
      ],
      correct_answer_position: 0,
      answer_selected: null,
      tip_response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina.",
      response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina."
    },
    {
      question_id: 39,
      case: "Trabajador de la construcción que acude a consulta por la presencia de dermatosis localizada en manos, caracterizada por la presencia de lesiones eritematosas pruriginosas, se sospecha dermatitis por contacto.",
      question: "¿Cuál es el principal alérgeno encontrado?",
      isAnswered: false, answers_list: [
        "resina epoxi, cromo, niquel, cobalto",
        "resorcinol, niquel, derivador acrílicos",
        "Látex, formaldehido, abitol",
        "Propoliz, cloracetamida, resinas de maderas",
      ],
      correct_answer_position: 0,
      answer_selected: null,
      tip_response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina.",
      response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina."
    },
    {
      question_id: 40,
      case: "Trabajador de la construcción que acude a consulta por la presencia de dermatosis localizada en manos, caracterizada por la presencia de lesiones eritematosas pruriginosas, se sospecha dermatitis por contacto.",
      question: "¿Cuál es el principal alérgeno encontrado?",
      isAnswered: false, answers_list: [
        "resina epoxi, cromo, niquel, cobalto",
        "resorcinol, niquel, derivador acrílicos",
        "Látex, formaldehido, abitol",
        "Propoliz, cloracetamida, resinas de maderas",
      ],
      correct_answer_position: 0,
      answer_selected: null,
      tip_response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina.",
      response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina."
    },
    {
      question_id: 41,
      case: "Trabajador de la construcción que acude a consulta por la presencia de dermatosis localizada en manos, caracterizada por la presencia de lesiones eritematosas pruriginosas, se sospecha dermatitis por contacto.",
      question: "¿Cuál es el principal alérgeno encontrado?",
      isAnswered: false, answers_list: [
        "resina epoxi, cromo, niquel, cobalto",
        "resorcinol, niquel, derivador acrílicos",
        "Látex, formaldehido, abitol",
        "Propoliz, cloracetamida, resinas de maderas",
      ],
      correct_answer_position: 0,
      answer_selected: null,
      tip_response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina.",
      response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina."
    },
    {
      question_id: 42,
      case: "Trabajador de la construcción que acude a consulta por la presencia de dermatosis localizada en manos, caracterizada por la presencia de lesiones eritematosas pruriginosas, se sospecha dermatitis por contacto.",
      question: "¿Cuál es el principal alérgeno encontrado?",
      isAnswered: false, answers_list: [
        "resina epoxi, cromo, niquel, cobalto",
        "resorcinol, niquel, derivador acrílicos",
        "Látex, formaldehido, abitol",
        "Propoliz, cloracetamida, resinas de maderas",
      ],
      correct_answer_position: 0,
      answer_selected: null,
      tip_response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina.",
      response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina."
    },
    {
      question_id: 43,
      case: "Trabajador de la construcción que acude a consulta por la presencia de dermatosis localizada en manos, caracterizada por la presencia de lesiones eritematosas pruriginosas, se sospecha dermatitis por contacto.",
      question: "¿Cuál es el principal alérgeno encontrado?",
      isAnswered: false, answers_list: [
        "resina epoxi, cromo, niquel, cobalto",
        "resorcinol, niquel, derivador acrílicos",
        "Látex, formaldehido, abitol",
        "Propoliz, cloracetamida, resinas de maderas",
      ],
      correct_answer_position: 0,
      answer_selected: null,
      tip_response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina.",
      response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina."
    },
    {
      question_id: 44,
      case: "Trabajador de la construcción que acude a consulta por la presencia de dermatosis localizada en manos, caracterizada por la presencia de lesiones eritematosas pruriginosas, se sospecha dermatitis por contacto.",
      question: "¿Cuál es el principal alérgeno encontrado?",
      isAnswered: false, answers_list: [
        "resina epoxi, cromo, niquel, cobalto",
        "resorcinol, niquel, derivador acrílicos",
        "Látex, formaldehido, abitol",
        "Propoliz, cloracetamida, resinas de maderas",
      ],
      correct_answer_position: 0,
      answer_selected: null,
      tip_response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina.",
      response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina."
    },
    {
      question_id: 45,
      case: "Trabajador de la construcción que acude a consulta por la presencia de dermatosis localizada en manos, caracterizada por la presencia de lesiones eritematosas pruriginosas, se sospecha dermatitis por contacto.",
      question: "¿Cuál es el principal alérgeno encontrado?",
      isAnswered: false, answers_list: [
        "resina epoxi, cromo, niquel, cobalto",
        "resorcinol, niquel, derivador acrílicos",
        "Látex, formaldehido, abitol",
        "Propoliz, cloracetamida, resinas de maderas",
      ],
      correct_answer_position: 0,
      answer_selected: null,
      tip_response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina.",
      response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina."
    },
    {
      question_id: 46,
      case: "Trabajador de la construcción que acude a consulta por la presencia de dermatosis localizada en manos, caracterizada por la presencia de lesiones eritematosas pruriginosas, se sospecha dermatitis por contacto.",
      question: "¿Cuál es el principal alérgeno encontrado?",
      isAnswered: false, answers_list: [
        "resina epoxi, cromo, niquel, cobalto",
        "resorcinol, niquel, derivador acrílicos",
        "Látex, formaldehido, abitol",
        "Propoliz, cloracetamida, resinas de maderas",
      ],
      correct_answer_position: 0,
      answer_selected: null,
      tip_response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina.",
      response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina."
    },
    {
      question_id: 47,
      case: "Trabajador de la construcción que acude a consulta por la presencia de dermatosis localizada en manos, caracterizada por la presencia de lesiones eritematosas pruriginosas, se sospecha dermatitis por contacto.",
      question: "¿Cuál es el principal alérgeno encontrado?",
      isAnswered: false, answers_list: [
        "resina epoxi, cromo, niquel, cobalto",
        "resorcinol, niquel, derivador acrílicos",
        "Látex, formaldehido, abitol",
        "Propoliz, cloracetamida, resinas de maderas",
      ],
      correct_answer_position: 0,
      answer_selected: null,
      tip_response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina.",
      response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina."
    },
    {
      question_id: 48,
      case: "Trabajador de la construcción que acude a consulta por la presencia de dermatosis localizada en manos, caracterizada por la presencia de lesiones eritematosas pruriginosas, se sospecha dermatitis por contacto.",
      question: "¿Cuál es el principal alérgeno encontrado?",
      isAnswered: false, answers_list: [
        "resina epoxi, cromo, niquel, cobalto",
        "resorcinol, niquel, derivador acrílicos",
        "Látex, formaldehido, abitol",
        "Propoliz, cloracetamida, resinas de maderas",
      ],
      correct_answer_position: 0,
      answer_selected: null,
      tip_response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina.",
      response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina."
    },
    {
      question_id: 49,
      case: "Trabajador de la construcción que acude a consulta por la presencia de dermatosis localizada en manos, caracterizada por la presencia de lesiones eritematosas pruriginosas, se sospecha dermatitis por contacto.",
      question: "¿Cuál es el principal alérgeno encontrado?",
      isAnswered: false, 
      answers_list: [
        "resina epoxi, cromo, niquel, cobalto",
        "resorcinol, niquel, derivador acrílicos",
        "Látex, formaldehido, abitol",
        "Propoliz, cloracetamida, resinas de maderas",
      ],
      correct_answer_position: 0,
      answer_selected: null,
      tip_response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina.",
      response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina."
    },
    {
      question_id: 50,
      case: "Trabajador de la construcción que acude a consulta por la presencia de dermatosis localizada en manos, caracterizada por la presencia de lesiones eritematosas pruriginosas, se sospecha dermatitis por contacto.",
      question: "¿Cuál es el principal alérgeno encontrado?",
      isAnswered: false,
      answers_list: [
        "resina epoxi, cromo, niquel, cobalto",
        "resorcinol, niquel, derivador acrílicos",
        "Látex, formaldehido, abitol",
        "Propoliz, cloracetamida, resinas de maderas",
      ],
      correct_answer_position: 0,
      answer_selected: null,
      tip_response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina.",
      response: "El tratamiento de primera línea en casos nunca tratados es la combinación de isoniazida, rifampicina, piranzinamida y etambutol durante dos meses, los siguientes cuatro sólo isoniazida / rifampicina."
    }
  ]); 

  const handleSetAnswerToQuestion = (value, index) => {
    setQuestions((prev) => {
      return prev?.map((question, i) => {
        if (i !== index) return question;
        return { ...question, answer_selected: value, isAnswered: true }

      })
    })
  }

  return (
    <SimulatorContext.Provider value={{
      questions,
      handleSetAnswerToQuestion
    }}>
      {children}
    </SimulatorContext.Provider>
  )
}

export default SimulatorProvider;