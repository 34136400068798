import React from "react";

const ChevronRight = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8" fill="none">
      <path
        d="M5.66233 0.48338L0.9467 6.87603C0.93428 6.89273 0.926562 6.91281 0.924431 6.93395C0.9223 6.95509 0.925844 6.97643 0.934655 6.99554C0.943466 7.01464 0.957188 7.03072 0.974243 7.04194C0.991299 7.05316 1.011 7.05906 1.03108 7.05896H2.06634C2.13197 7.05896 2.19491 7.02634 2.23509 6.97246L5.99983 1.86741L9.76456 6.97246C9.80474 7.02776 9.86768 7.05896 9.93331 7.05896H10.9686C11.0583 7.05896 11.1079 6.94977 11.0529 6.87603L6.33733 0.48338C6.2973 0.429047 6.2461 0.385093 6.18761 0.354862C6.12913 0.32463 6.06491 0.308917 5.99983 0.308917C5.93474 0.308917 5.87052 0.32463 5.81204 0.354862C5.75356 0.385093 5.70235 0.429047 5.66233 0.48338Z"
        fill="black"
      />
    </svg>
  );
};

export default ChevronRight;
