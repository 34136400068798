import React from "react";

const Clock = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path
        d="M7.00195 0.00302124C3.13633 0.00302124 0.00195312 3.1374 0.00195312 7.00302C0.00195312 10.8686 3.13633 14.003 7.00195 14.003C10.8676 14.003 14.002 10.8686 14.002 7.00302C14.002 3.1374 10.8676 0.00302124 7.00195 0.00302124ZM7.00195 12.8155C3.79258 12.8155 1.18945 10.2124 1.18945 7.00302C1.18945 3.79365 3.79258 1.19052 7.00195 1.19052C10.2113 1.19052 12.8145 3.79365 12.8145 7.00302C12.8145 10.2124 10.2113 12.8155 7.00195 12.8155Z"
        fill="black"
        fillOpacity="0.25"
      />
      <path
        d="M9.73176 8.97968L7.50364 7.36874V3.50156C7.50364 3.43281 7.44739 3.37656 7.37864 3.37656H6.62708C6.55833 3.37656 6.50208 3.43281 6.50208 3.50156V7.80468C6.50208 7.84531 6.52083 7.88281 6.55364 7.90624L9.13801 9.79062C9.19426 9.83124 9.27239 9.81874 9.31301 9.76406L9.75989 9.15468C9.80051 9.09687 9.78801 9.01874 9.73176 8.97968Z"
        fill="black"
        fillOpacity="0.25"
      />
    </svg>
  );
};

export default Clock;
